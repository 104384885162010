<script setup lang="ts">
import { useHead } from '@vueuse/head'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

useHead({
	title: t('404.title')
})
</script>

<template>
	<div>
		<section>
			<h2>{{ $t('404.title') }}</h2>
			<p class="text-center">
				{{ $t('404.description') }}
			</p>
		</section>
	</div>
</template>

<style lang="css" scoped>
</style>