<script setup lang="ts">
import { ref } from 'vue'
import SubmitButton from './SubmitButton.vue'
import { useTextareaAutosize } from '@vueuse/core';

const props = withDefaults(defineProps<{autofocus: boolean, name: string, sex: string, buttonLabel: string, submitBtn: typeof SubmitButton | null, messageLabel: string | null}>(),
{
	name: '',
	sex: '',
	submitBtn: null,
	autofocus: false,
	messageLabel: null,
})

const name = ref<string>(props.name)
const sex = ref<string>(props.sex)

const { textarea, input } = useTextareaAutosize({ styleProp: 'minHeight' })
</script>

<template>
	<form @submit.prevent="$emit('create', name, sex, input)">
		<div class="group input">
			<label class="label" for="name">{{ $t('create.form.name.label') }}</label>
			<input
				type="text"
				class="input"
				id="name"
				maxlength="40"
				autocomplete="name"
				spellcheck="false"
				v-model="name"
				:placeholder="$t('create.form.name.placeholder')"
				:autofocus="autofocus"
				required>
		</div>
		<div class="group input">
			<label class="label">{{ $t('create.form.sex.label') }}</label>
			<ul class="input">
				<li>
					<input type="radio" name="sex" value="male" id="sex-m" v-model="sex" required>
					<label for="sex-m">
						{{ $t('create.form.sex.genders.male') }}
					</label>
				</li>
				<li>
					<input type="radio" name="sex" value="female" id="sex-f" v-model="sex" required>
					<label for="sex-f">
						{{ $t('create.form.sex.genders.female') }}
					</label>
				</li>
				<li>
					<input type="radio" name="sex" value="x" id="sex-x" v-model="sex" required>
					<label for="sex-x">
						{{ $t('create.form.sex.genders.x') }}
					</label>
				</li>
			</ul>
		</div>
		<div class="group input textarea" style="margin-top: 3rem" v-if="messageLabel">
			<label class="label" id="message">{{ messageLabel }}</label>
			<textarea
				ref="textarea"
				class="input"
				id="message"
				maxlength="300"
				rows="1"
				v-model.trim="input"
				:placeholder="$t('create.form.message.placeholder')"
				@keydown.enter.prevent="null"></textarea>
		</div>
		<div class="group button">
			<SubmitButton ref="submitBtn" :text="buttonLabel" />
			<span class="info">{{ $t('create.form.info', {button: buttonLabel.replace(/[^\w\s!]/g,'').trim()}) }}</span>
		</div>
	</form>
</template>

<style lang="css" scoped>
form
{
	display: flex;
	flex-direction: column;
	gap: 1.3rem;
}

form div.group.button
{
	margin-top: 2rem;
	text-align: center;
}

form div.group.button button
{
	display: block;
	width: 100%;
}

form div.group.input
{
	display: flex;
	align-items: stretch;
	border-radius: 50px;
	box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
	overflow: hidden;
}

form div.group.input.textarea
{
	flex-direction: column;
}

form div.group.input.textarea .label
{
	align-items: start;
	justify-content: center;
	flex: 0;
	white-space: normal;
	text-align: center;
}

div.group .label
{
	display: flex;
	align-items: center;
	justify-content: flex-end;
	flex: 0 230px;
	white-space: nowrap;
	padding: .6rem 1rem;
	font-size: 1.5rem;
	font-weight: 300;
	background: #f6f6f6;
	box-sizing: border-box;
	transition: background-color .3s ease, color .15s ease;
}

div.group:focus-within .label
{
	color: #fff;
	background: var(--shade1-color);
}

form div.group .input
{
	display: block;
	flex: 1;
}

form div.group input.input
{
	display: block;
	border: none;
	padding: .6rem 1rem;
	outline: none;
	background: transparent;
	box-sizing: border-box;
	color: var(--shade1-color);
	width: 100%;
	height: 100%;
	font-size: 1.5rem;
	font-weight: 600;
}

form div.group input.input::placeholder
{
	color: #999;
	font-weight: 300;
}

form div.group.input.textarea textarea
{
	display: block;
	border: none;
	padding: .6rem 1rem 2rem;
	outline: none;
	background: transparent;
	box-sizing: border-box;
	width: 100%;
	font-size: 1.5rem;
	-ms-overflow-style: none;
	scrollbar-width: none;
}

form div.group.input.textarea textarea::-webkit-scrollbar
{
	display: none;
}

form div.group.input.textarea textarea::placeholder
{
	color: #999;
	font-weight: 300;
}

form div.group ul.input
{
	display: flex;
	align-items: stretch;
	list-style: none;
	margin: 0;
	padding: 0;
}

form div.group ul.input > li
{
	position: relative;
	display: block;
	flex: 1;
	height: 100%;
}

form div.group ul.input input[type="radio"]
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	outline: none;
	border: none;
	opacity: 0;
	cursor: pointer;
	z-index: 5;
}

form div.group ul.input label
{
	display: flex;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	padding: .6rem 1rem;
	box-sizing: border-box;
	font-size: 1.5rem;
	font-weight: 300;
	background: transparent;
	color: #999;
	transition: background-color .3s ease, color .15s ease, font-weight .2s ease;
}

form div.group ul.input input[type="radio"]:checked + label
{
	color: var(--shade1-color);
	font-weight: 600;
}

@media screen and (max-width: 650px)
{
	div.group .label
	{
		flex: 0 200px;
		font-size: 1.25rem;
	}

	form div.group .input,
	form div.group ul.input label
	{
		font-size: 1.25rem !important;
	}
}

@media screen and (max-width: 500px)
{
	form div.group.input
	{
		flex-direction: column;
		border-radius: 20px;
	}

	div.group .label
	{
		flex: 0;
		align-items: normal;
		justify-content: center;
	}

	form div.group .input
	{
		text-align: center;
	}
}
</style>