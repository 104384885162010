<script setup lang="ts">
import AnswerListForm from './AnswerListForm.vue'
import type IQuestionClient from '@/interfaces/IQuestionClient'

const questions = defineModel<Array<IQuestionClient>>('q')
</script>

<template>
	<div class="questions">
		<dl v-for="(question, qIdx) in questions" :key="question.id">
			<dt>
				<i>{{ (qIdx + 1) }}</i>
				<h3 class="question">{{ question.question }}</h3>
			</dt>
			<dd>
				<AnswerListForm
					v-model:selected="question.selected"
					v-model:custom="(question.custom as string)"
					:idx="qIdx"
					:name="'answer-' + question.id"
					:answers="question.answers"
					:allow-custom="true" />
			</dd>
		</dl>
	</div>
</template>

<style lang="css" scoped>
div.questions
{
	display: flex;
	flex-direction: column;
	gap: 2rem;
}

div.questions dl
{
	margin: 0;
}

div.questions dl > dt
{
	display: flex;
	align-items: center;
}

div.questions dl > dt i
{
	display: flex;
	flex: 0 60px;
	height: 60px;
	align-items: center;
	justify-content: center;
	counter-increment: step;
	content: counter(step);
	color: #fff;
	font-style: normal;
	border-radius: 50%;
	font-size: 1.6rem;
	font-weight: 300;
	user-select: none;
	cursor: default;
}

div.questions dl:nth-child(3n + 1) > dt i
{
	background: var(--shade3-color);
}

div.questions dl:nth-child(3n + 2) > dt i
{
	background: var(--shade2-color);
}

div.questions dl:nth-child(3n) > dt i
{
	background: var(--shade1-color);
}

div.questions dl > dt h3
{
	flex: 1;
	font-size: 1.5rem;
	margin-left: 20px;
	font-weight: 300;
}

div.questions dl > dd
{
	margin: 10px 0 0 80px;
}

dl:nth-child(3n + 1) ul.answers :deep(li) .option.checked > span
{
	color: #fff !important;
	background: var(--shade3-color) !important;
}

dl:nth-child(3n + 2) ul.answers :deep(li) .option.checked > span
{
	color: #fff !important;
	background: var(--shade2-color) !important;
}

dl:nth-child(3n) ul.answers :deep(li) .option.checked > span
{
	color: #fff !important;
	background: var(--shade1-color) !important;
}

dl:nth-child(3n + 1) ul.answers :deep(li) .option.checked > textarea.custom
{
	color: #fff !important;
	background: var(--shade3-color) !important;
}

dl:nth-child(3n + 2) ul.answers :deep(li) .option.checked > textarea.custom
{
	color: #fff !important;
	background: var(--shade2-color) !important;
}

dl:nth-child(3n) ul.answers :deep(li) .option.checked > textarea.custom
{
	color: #fff !important;
	background: var(--shade1-color) !important;
}

@media screen and (max-width: 550px)
{
	div.questions dl > dd
	{
		margin-left: 0;
	}
}

@media screen and (max-width: 450px)
{
	div.questions dl > dt i
	{
		flex: 0 50px;
		height: 50px;
	}

	div.questions dl > dt h3
	{
		font-size: 1.25rem;
		margin-top: 0;
		margin-bottom: 0;
	}

	div.questions dl > dd
	{
		margin-top: 16px;
	}
}
</style>