<script setup lang="ts">
import CreateForm from '@/components/CreateForm.vue'
import router from '@/router'
import { createQuizStorage } from '@/stores'
import { useI18n, I18nT } from 'vue-i18n'
import { useHead } from '@vueuse/head'
import { analytics } from '@/firebase'
import { setUserProperties, logEvent } from 'firebase/analytics'

import lcpt from '../assets/friendshiptest.webp'
import lcpb from '../assets/best-friend-quiz.webp'

const { t } = useI18n()

const quiz = createQuizStorage()
const questionNum = import.meta.env.VITE_QUESTION_COUNT

if( quiz.isDone() )
{
	router.push('/result')
}

const create = ( name: string, sex: string ) =>
{
	quiz.create(name, sex)

	setUserProperties( analytics, {name, sex} )
	logEvent( analytics, 'quiz_create', {name, sex} )

	router.push('/create')
}

useHead({
	title: t('title', {year: new Date().getFullYear()}),
	link: [
		{
			rel: 'preload',
			as: 'image' as const,
			href: lcpt,
			type: 'image/webp',
		},
		{
			rel: 'preload',
			as: 'image' as const,
			href: lcpb,
			type: 'image/webp',
		},
	]
})
</script>

<template>
	<div>
		<section>
			<h2>{{ $t('home.title') }}</h2>
			<div class="instr">
				<img :src="lcpt" :alt="$t('home.imgalt.top')" width="225" height="196">
				<ol>
					<li>
						<I18nT keypath="home.steps.step1.text" tag="h3" scope="global">
							<b>{{ $t('home.steps.step1.bold', {num: questionNum}) }}</b>
						</I18nT>
					</li>
					<li>
						<I18nT keypath="home.steps.step2.text" tag="h3" scope="global">
							<b>{{ $t('home.steps.step2.bold') }}</b>
						</I18nT>
					</li>
					<li>
						<I18nT keypath="home.steps.step3.text" tag="h3" scope="global">
							<b>{{ $t('home.steps.step3.bold') }}</b>
						</I18nT>
					</li>
				</ol>
			</div>
			<CreateForm
				@create="create"
				name=""
				sex=""
				:button-label="$t('home.button')"
				:submit-btn="null"
				:message-label="null"
				:autofocus="true" />
		</section>
		<div class="img">
			<img :src="lcpb" :alt="$t('home.imgalt.middle')" width="350" height="335">
		</div>
		<section>
			<h2>{{ $t('home.info.title') }}</h2>
			<div class="grid">
				<div>
					<h3>{{ $t('home.info.boxes.box1.title') }}</h3>
					<p>
						{{ $t('home.info.boxes.box1.body', {num: questionNum}) }}
					</p>
				</div>
				<div>
					<h3>{{ $t('home.info.boxes.box2.title') }}</h3>
					<p>
						{{ $t('home.info.boxes.box2.body') }}
					</p>
				</div>
				<div>
					<h3>{{ $t('home.info.boxes.box3.title') }}</h3>
					<p>
						{{ $t('home.info.boxes.box3.body') }}
					</p>
				</div>
				<div>
					<h3>{{ $t('home.info.boxes.box4.title') }}</h3>
					<p>
						{{ $t('home.info.boxes.box4.body') }}
					</p>
				</div>
			</div>
		</section>
	</div>
</template>

<style lang="css" scoped>
div.img
{
	width: 100%;
	max-width: 780px;
	text-align: center;
}

div.img > img
{
	display: inline-block;
	width: 100%;
	max-width: 350px;
}

div.instr
{
	display: flex;
	align-items: center;
	margin: 0 0 3rem;
}

div.instr img
{
	display: block;
	width: 225px;
}

ol
{
	counter-reset: step;
	list-style: none;
	display: flex;
	flex-direction: column;
	gap: .6rem;
	margin: 0;
	padding: 0 0 0 40px;
}

ol > li
{
	display: flex;
	align-items: center;
	margin: 0;
}

ol > li::before
{
	flex: 0 60px;
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
	counter-increment: step;
	content: counter(step);
	color: #fff;
	border-radius: 50%;
	font-size: 1.6rem;
	font-weight: 300;
}

ol > li:nth-child(1)::before
{
	background: var(--shade3-color);
}

ol > li:nth-child(2)::before
{
	background: var(--shade2-color);
}

ol > li:nth-child(3)::before
{
	background: var(--shade1-color);
}

ol > li h3
{
	margin: 0;
	flex: 1;
	font-size: 1.25rem;
	margin-left: 20px;
	font-weight: 300;
}

ol > li h3 :deep(b)
{
	font-weight: 600;
}

div.grid
{
	display: flex;
	flex-wrap: wrap;
}

div.grid > div
{
	flex-grow: 0;
	flex-shrink: 0;
	flex-basis: auto;
	width: 50%;
	text-align: center;
	padding: 20px;
	box-sizing: border-box;
	text-shadow: 0 2px 1px #fff;
	text-align: justify;
}

div.grid h3
{
	font-weight: 500;
	margin: 15px 0 30px;
	color: var(--shade2-color);
	font-size: 1.1rem;
	letter-spacing: .1rem;
	text-align: center;
	text-transform: uppercase;
	text-align: center;
}

@media screen and (max-width: 650px)
{
	ol 
	{
		padding-left: 20px;
	}

	ol > li::before
	{
		flex: 0 50px;
		height: 50px;
	}

	ol > li h3
	{
		margin-left: 14px;
		font-size: 1.15rem;
	}

	div.instr img
	{
		width: 185px;
	}
}

@media screen and (max-width: 600px)
{
	div.grid
	{
		flex-direction: column;
	}

	div.grid > div
	{
		width: 100%;
	}
}

@media screen and (max-width: 500px)
{
	div.instr
	{
		flex-direction: column;
		gap: 2rem;
	}

	div.instr img
	{
		width: 225px;
	}

	ol 
	{
		width: 100%;
		padding-left: 0;
	}

	ol > li h3
	{
		font-size: 1.25rem;
	}
}
</style>