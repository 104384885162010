<script setup lang="ts">
import Alert from '@/components/Alert.vue'
import { db, functions } from '@/firebase'
import { createQuizStorage, joinQuizStorage } from '@/stores'
import { useHead } from '@vueuse/head'
import { collection, documentId, getDocs, limit, query, Timestamp, where } from 'firebase/firestore'
import { inject, onMounted, onUnmounted, reactive, ref, watch } from 'vue'
import { RouterLink, useRoute } from 'vue-router'
import type IQuizClient from '@/interfaces/IQuizClient'
import type IQuestionRes from '@/interfaces/IQuestionRes'
import type IQuestionClient from '@/interfaces/IQuestionClient'
import AnswerListForm from '@/components/AnswerListForm.vue'
import CreateForm from '@/components/CreateForm.vue'
import { httpsCallable } from 'firebase/functions'
import router from '@/router'
import type SubmitButton from '@/components/SubmitButton.vue'
import { getCustomTestHeader, getQuestionAnswers, getQuestionImage, getQuiz, parseText } from '@/functions'
import type IQuestionAnswerPair from '@/interfaces/IQuestionAnswerPair'
import { useTitle } from '@vueuse/core'
import type IAnswerClient from '@/interfaces/IAnswerClient'
import { useI18n } from 'vue-i18n'
import { analytics } from '@/firebase'
import { setUserProperties, logEvent } from 'firebase/analytics'

//const { getCustomTestHeader, getQuestionAnswers, getQuestionImage, getQuiz, parseText } = await import('../functions')

const { t, locale } = useI18n()

const createdQuiz = createQuizStorage()
const joinedQuiz = joinQuizStorage()
const route = useRoute()

const form = ref<HTMLFormElement>()
const submitBtn = ref<typeof SubmitButton | null>()

const createAttendee = httpsCallable<{
	id: string,
	name: string,
	sex: string,
	created: Date,
	message: string | null,
	answers: IQuestionAnswerPair,
	msgToken: string | null,
	host: string,
},
{
	id: string,
	error:
	{
		message: string,
		code: number
	}
}>(functions, 'createAttendee')

const setHeaderTitle = inject('setHeaderTitle') as (title: string) => void
const isTestBarShown = inject('isTestBarShown') as () => boolean
const activateTestBar = inject('activateTestBar') as (all: number) => void
const setTestBar = inject('setTestBar') as (step: number) => void
const deactivateTestBar = inject('deactivateTestBar') as () => void

const quizId = route.params.id as string
const quizData = ref<IQuizClient>()
const question = reactive<IQuestionClient>({id: '', question: '', answers: [], sponsored: false, loaded: false})
const answers = reactive<Array<IAnswerClient>>([])
const answerSelected = ref<string>()
const answerCount = ref<number>(joinedQuiz.getAnswerCount())

const quizName = ref<string>()
const quizSex = ref<string>()
const quizCreated = ref<Date>()
const quizQuestionCount = ref<number>()

const showLoader = ref<boolean>(true)
const alertType = ref<string>('info')
const alertText = ref<string | null>(null)

const loadedQuestions: Array<IQuestionRes> = []

const loadQuestion = async ( quiz: IQuizClient ) =>
{
	const lang = locale.value

	const questionIds = quiz.questions ? Object.keys( quiz.questions ) : []
	const answeredQuestionIds = quiz.answers ? Object.keys( quiz.answers ) : []

	const findIds = questionIds.filter(qId => answeredQuestionIds.indexOf( qId ) < 0)

	let q = null
	if( findIds.length > 0 )
	{
		q = query
		(
			collection( db, 'questions' ),
			where( documentId(), 'in', findIds ),
			limit(1)
		)
	}
	else
	{
		q = query( collection( db, 'questions' ), limit(1) )
	}

	const questionDocs = await getDocs( q )

	if( questionDocs.empty )
	{
		throw new Error(t('test.errors.no_questions'))
	}

	for( const doc of questionDocs.docs )
	{
		const data = Object.assign({id: doc.id}, doc.data()) as IQuestionRes

		let genderQuestion = null
		switch( quizSex.value )
		{
			case 'male':
				genderQuestion = ( data.questions.male && lang in data.questions.male ) ? data.questions.male[lang] : null
			break
			case 'female':
				genderQuestion = ( data.questions.female && lang in data.questions.female ) ? data.questions.female[lang] : null
			break
		}

		let questionImage = null

		if( data.images && data.images.file )
		{
			questionImage = await getQuestionImage( data.images.file || null )
			data.images.file = questionImage as string
		}

		question.id = data.id
		question.question = parseText(genderQuestion || data.questions.question[lang], quizName.value as string)
		question.images = { file: questionImage, source: data.images?.source }
		question.sponsored = data.sponsored
		question.loaded = true

		let custom = null
		if( quiz.questions )
		{
			custom = quiz.questions[question.id].custom
		}

		getQuestionAnswers( data, quiz.name as string, quiz.sex as string, custom ).forEach(ans => answers.push(ans))

		loadedQuestions.push(data)
	}
}

const reloadQuestion = async ( quiz: IQuizClient ) =>
{
	const lang = locale.value
	answers.splice(0, answers.length)
	answerSelected.value = ''

	for( const data of loadedQuestions )
	{
		let genderQuestion = null
		switch( quizSex.value )
		{
			case 'male':
				genderQuestion = ( data.questions.male && lang in data.questions.male ) ? data.questions.male[lang] : null
			break
			case 'female':
				genderQuestion = ( data.questions.female && lang in data.questions.female ) ? data.questions.female[lang] : null
			break
		}

		question.id = data.id
		question.question = parseText(genderQuestion || data.questions.question[lang], quizName.value as string)
		question.images = { file: data.images?.file || null, source: data.images?.source }
		question.sponsored = data.sponsored
		question.loaded = true

		let custom = null
		if( quiz.questions )
		{
			custom = quiz.questions[question.id].custom
		}

		getQuestionAnswers( data, quiz.name as string, quiz.sex as string, custom ).forEach(ans => answers.push(ans))
	}
}

const validate = (e: PointerEvent) =>
{
	if( !form.value?.checkValidity() )
	{
		form.value?.reportValidity()
		e.preventDefault()
	}

	logEvent( analytics, 'attendee_answered', {quizId, questionId: question.id} )
}

const finish = async (name: string, sex: string, message: string | null) =>
{
	try
	{
		const id = quizData.value?.id as string
		const created = new Date()
		const answers = joinedQuiz.getAnswers()
		const msgToken = joinedQuiz.getMsgToken()

		const result = await createAttendee({
			id,
			name,
			sex,
			created,
			message,
			answers,
			msgToken,
			host: window.location.host,
		})

		if( !result.data )
		{
			throw new Error( t('test.errors.no_attendee') )
		}
		else if( !result.data.id )
		{
			throw new Error( result.data.error.message )
		}

		const attendeeId = result.data.id

		joinedQuiz.clear()

		setUserProperties( analytics, {name, sex} )
		logEvent( analytics, 'attendee_finished', {quizId: id, attendeeId, name, sex} )

		router.push(`/test/${quizId}/attendee/${attendeeId}`)
	}
	catch( e )
	{
		submitBtn.value?.reset()
		alertType.value = 'fail'
		alertText.value = (e as Error).message
	}

}

onMounted(async () =>
{
	try
	{
		let quiz = null
		let create = false

		// Wenn kein laufendes Spiel existiert oder das Spiel gewechselt wurde
		if( !joinedQuiz.isExisting() || joinedQuiz.getId() !== quizId )
		{
			quiz = await getQuiz( quizId )
			create = true
		}
		else
		{
			quiz = joinedQuiz.getObject()
		}

		if( !quiz )
		{
			throw new Error(t('test.errors.no_quiz'))
		}

		if( create )
		{
			joinedQuiz.create(	quiz.id,
								quiz.name,
								quiz.sex,
								(quiz.created as Timestamp).toDate(),
								quiz.questions,
								quiz.msgToken )
		}

		quizName.value = joinedQuiz.getName()
		quizSex.value = joinedQuiz.getSex()
		quizCreated.value = joinedQuiz.getCreated() as Date
		quizQuestionCount.value = joinedQuiz.getQuestionCount()

		quizData.value = quiz

		setHeaderTitle( getCustomTestHeader( quiz.name as string ) )

		if( quizData.value.id === createdQuiz.getId() )
		{
			alertType.value = 'warning'
			alertText.value = `<strong>${ t('test.question.own_quiz.title') }</strong><br>${ t('test.question.own_quiz.text') }`
		}

		if( answerCount.value !== quizQuestionCount.value )
		{
			await loadQuestion( quizData.value )

			if( !question.loaded )
			{
				throw new Error(t('test.errors.no_question'))
			}

			const answerPairs = joinedQuiz.getAnswers()
			answerSelected.value = answerPairs ? answerPairs[question.id] : undefined
		}

		if( !isTestBarShown() )
		{
			activateTestBar( quizQuestionCount.value )
		}

		setTestBar( answerCount.value )

		useTitle(t('test.head.title', {name: quizName.value}))
	}
	catch( e )
	{
		joinedQuiz.clear()
		showLoader.value = false
		alertType.value = 'fail'
		alertText.value = (e as Error).message
	}
})

onUnmounted(() =>
{
	if( !route.path.match(/^\/test\/([A-Za-z0-9]+)$/) )
	{
		deactivateTestBar()
		setHeaderTitle(t('title', {year: new Date().getFullYear()}))
	}
})

watch(answerSelected, (newVal, oldVal) =>
{
	if( newVal !== oldVal )
	{
		joinedQuiz.addAnswerId({[question.id]: answerSelected.value as string})
	}
})

watch(locale, () =>
{
	if( quizData.value )
	{
		reloadQuestion( quizData.value )
	}
})

useHead({
	bodyAttrs: {class: 'bottom-space'},
})
</script>

<template>
	<div>
		<Alert :type="alertType" :text="alertText" v-show="alertText" />
		<div class="deeper" v-if="quizData">
			<section>
				<div class="head">
					<h2>{{ $t('test.title', {name: quizName}) }}</h2>
					<span class="info">{{ t('test.created_at', {date: quizCreated?.toLocaleString(locale, {dateStyle: 'short'})}) }}</span>
				</div>
			</section>
			<section v-if="answerCount === quizQuestionCount">
				<h3>{{ $t('test.finished.title') }}</h3>
				<p>{{ $t('test.finished.description', {name: quizName}) }}</p>
				<CreateForm @create="finish" :submitBtn="submitBtn" :button-label="$t('test.finished.form.button')" :messageLabel="$t('test.finished.form.message', {name: quizName})" :autofocus="false" />
			</section>
			<section :class="{'has-img': question.images?.file}" v-else-if="question.loaded">
				<div class="img" :style="{ 'background-image': `url(${question.images?.file})` }" v-if="question.images?.file">
					<a :href="question.images?.source" target="_blank" v-if="question.images?.source">{{ $t('test.question.source') }}</a>
					<h3>{{ question.question }}</h3>
				</div>
				<h3 v-if="!question.images?.file">{{ question.question }}</h3>
				<form ref="form">
					<AnswerListForm :answers="answers" v-model:selected="answerSelected" :allow-custom="false" />
				</form>
				<div class="group button">
					<RouterLink
						@click.capture="validate"
						:to="`/test/${quizId}?_=${Date.now()}`"
						class="button">
						{{ $t('test.question.button') }}
					</RouterLink>
					<p class="info">{{ $t('test.question.info') }}</p>
				</div>
			</section>
			<section class="loading" v-else-if="showLoader">
				<div class="loader">
					<img src="../assets/loader.svg" alt="">
					<span>{{ $t('test.question.loading') }}</span>
				</div>
			</section>
		</div>
		<section class="loading" v-else-if="showLoader">
			<div class="loader">
				<img src="../assets/loader.svg" alt="">
				<span>{{ $t('test.loading') }}</span>
			</div>
		</section>
		<RouterLink to="/" class="big-btn" v-else>
			<h2>{{ $t('buttons.ft.title') }}</h2>
			<p>{{ $t('buttons.ft.description') }}</p>
		</RouterLink>
	</div>
</template>

<style lang="css" scoped>
.head
{
	margin: 0;
	text-align: center;
}

.head h2
{
	margin: 0;
}

.head .info
{
	margin-top: .2rem;
	font-size: .85rem;
	letter-spacing: .1rem;
	text-transform: uppercase;
}

section
{
	position: relative;
	overflow: hidden;
}

section.loading
{
	padding-top: 0;
	padding-bottom: 0;
}

section.loading .loader
{
	margin: 2rem 0;
}

section.has-img
{
	padding-top: calc(1.25rem + 200px) !important;
}

section .img
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 200px;
	overflow: hidden;
	background: center center no-repeat;
	background-size: cover;
}

section .img::before
{
	content: '';
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: linear-gradient(360deg, rgba(255,255,255,1) 5%, rgba(255,255,255,0) 100%);
}

section .img a
{
	position: absolute;
	display: block;
	top: 0;
	right: 0;
	padding: .3rem .6rem;
	font-size: .7rem;
	text-decoration: none;
	color: #fff;
	paint-order: stroke fill;
	-webkit-text-stroke: 1px #000;
}

section .img h3
{
	position: absolute;
	bottom: 0;
	left: 10px;
	right: 10px;
	text-align: center;
	font-weight: 700;
	margin: 0;
	-webkit-text-stroke: 5px #fff;
	paint-order: stroke fill;
}

section h3
{
	font-weight: 500;
	margin: 1rem 0 2rem;
	color: var(--primary-color);
	font-size: 1.2rem;
	letter-spacing: .1rem;
	text-align: center;
	text-transform: uppercase;
}

section p:not(.info)
{
	margin: 2rem 0;
	font-size: 1.3rem;
	font-weight: 400;
	text-align: center;
}

div.group.button
{
	margin-top: 2rem;
	text-align: center;
}

div.group.button button
{
	display: block;
	width: 100%;
}

a.button
{
	display: block;
	padding: calc(.6rem + 2px) 1rem;
	border-radius: 50px;
	background: var(--shade1-color);
	color: #fff;
	font-size: 1.5rem;
	font-weight: 600;
	text-decoration: none;
	box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
	transition: background-color .3s ease;
}

a.button:not(.disabled):hover
{
	background: var(--shade2-color);
}

a.button:not(.disabled):active
{
	box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
	background: var(--shade1-color);
	transform: translateY(2px);
}
</style>