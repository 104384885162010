<script setup lang="ts">
import Alert from '@/components/Alert.vue'
import QuestionListForm from '@/components/QuestionListForm.vue'
import SubmitButton from '@/components/SubmitButton.vue'
import { db, firebaseConfig, functions, messaging } from '@/firebase'
import type IQuestionClient from '@/interfaces/IQuestionClient'
import type IQuestionRes from '@/interfaces/IQuestionRes'
import router from '@/router'
import { createQuizStorage } from '@/stores'
import { useHead } from '@vueuse/head'
import { collection, documentId, getDocs, orderBy, query, where } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import { onMounted, reactive, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { analytics } from '@/firebase'
import { logEvent, setUserProperties } from 'firebase/analytics'
import { getToken } from 'firebase/messaging'

const { t, locale } = useI18n()

const submitBtn = ref<typeof SubmitButton>()
const alertType = ref<string>('info')
const alertText = ref<string | null>(null)

const loadedQuestions: Array<IQuestionRes> = []

const quiz = createQuizStorage()
let questions = reactive<Array<IQuestionClient>>([])
const createQuiz = httpsCallable<{
	name: string,
	sex: string,
	created: Date,
	questions: Array<{id: string, answer: string | null | undefined, custom: string | null | undefined}>,
	parentQuizId: string | null,
	parentAttendeeId: string | null,
	uuid: string,
	msgToken: string | null,
	host: string,
},
{
	id: string,
	error:
	{
		message: string,
		code: number
	}
}>(functions, 'createQuiz')

if( quiz.isDone() )
{
	router.push('/result')
}
else if( !quiz.isRunning() )
{
	router.push('/')
}

onMounted(async () =>
{
	try
	{
		if( !quiz.hasQuestions() )
		{
			await loadQuestions()
		}
		else
		{
			quiz.getQuestions().forEach(qes => questions.push( qes ))
		}
	}
	catch( e )
	{
		alertType.value = 'fail'
		alertText.value = (e as Error).message
	}
})

const loadQuestions = async () =>
{
	const lang = locale.value
	questions.splice(0, questions.length)

	const qRef = collection( db, 'questions' )

	const shuffledIds = (await getDocs( query( qRef, where( 'active', '==', true ), orderBy( 'priority', 'desc' ) ) )).docs.map(doc => doc.id)
	const selectedIds = shuffledIds.sort(() => 0.5 - Math.random()).slice(0, 10)

	const questionDocs = await getDocs( query( qRef, where( documentId(), 'in', selectedIds ) ) )

	if( questionDocs.empty )
	{
		throw new Error(t('attendee.errors.no_questions'))
	}
	
	for( const doc of questionDocs.docs.sort(() => 0.5 - Math.random()) )
	{
		const data = Object.assign({ id: doc.id }, doc.data()) as IQuestionRes

		const answers = data.answers.map((ans, idx) =>
		{
			return {idx: idx.toString(), answer: ( ( ans.me && lang in ans.me ) ? ans.me[lang] : null ) || ans.answer[lang], custom: false}
		})

		questions.push({
			id: data.id,
			question: ( ( data.questions.you && lang in data.questions.you ) ? data.questions.you[lang] : null ) || data.questions.question[lang],
			answers,
			selected: null,
			custom: null,
			sponsored: data.sponsored,
		})

		loadedQuestions.push(data)
		quiz.setQuestions( questions )
	}
}

const reloadQuestions = async () =>
{
	const lang = locale.value
	questions.splice(0, questions.length)

	for( const data of loadedQuestions )
	{
		const answers = data.answers.map((ans, idx) =>
		{
			return {idx: idx.toString(), answer: ( ( ans.me && lang in ans.me ) ? ans.me[lang] : null ) || ans.answer[lang], custom: false}
		})

		questions.push({
			id: data.id,
			question: ( ( data.questions.you && lang in data.questions.you ) ? data.questions.you[lang] : null ) || data.questions.question[lang],
			answers,
			selected: null,
			custom: null,
			sponsored: data.sponsored,
		})
	}

	quiz.setQuestions( questions )
}

const finish = async () =>
{
	try
	{
		alertText.value = null
		const created = new Date()
		const name = quiz.getName()
		const sex = quiz.getSex()
		const uuid = crypto.randomUUID()

		let msgToken = null
		if( Notification.permission === 'granted' )
		{
			msgToken = await getToken(messaging, {vapidKey: firebaseConfig.vapidKey})
			logEvent( analytics, 'notifications_activated' )
		}
	
		const result = await createQuiz({
			name,
			sex,
			created,
			questions: quiz.getQuestions().map(qes => ({id: qes.id, answer: qes.selected, custom: qes.custom})),
			parentQuizId: quiz.getParentQuizId(),
			parentAttendeeId: quiz.getParentAttendeeId(),
			uuid,
			msgToken,
			host: window.location.host,
		})
	
		if( !result.data )
		{
			throw new Error( t('create.errors.data_not_saved') )
		}
		else if( !result.data.id )
		{
			throw new Error( result.data.error.message )
		}

		const id = result.data.id
	
		quiz.setId( id )
		quiz.setCreated( created )
		quiz.setDone( true )
		quiz.setRunning( false )
		quiz.setUUID( uuid )

		setUserProperties( analytics, {name, sex} )
		logEvent( analytics, 'quiz_finished', {quizId: id, name, sex, parentQuizId: quiz.getParentQuizId(), parentAttendeeId: quiz.getParentAttendeeId()} )
	
		router.push('/result')
	}
	catch( e )
	{
		submitBtn.value?.reset()
		alertType.value = 'fail'
		alertText.value = (e as Error).message
	}
}

watch(locale, async () =>
{
	if( loadedQuestions.length === 0 )
	{
		await loadQuestions()
	}
	else
	{
		reloadQuestions()
	}
})

useHead({
	title: t('create.head.title'),
})
</script>

<template>
	<div>
		<Alert :type="alertType" :text="alertText" v-show="alertText" />
		<section>
			<form @submit.prevent="finish">
				<h2>{{ $t('create.title', {name: quiz.getName()}) }}</h2>
				<div class="loader" v-if="questions.length === 0">
					<img src="../assets/loader.svg" alt="">
					<span>{{ $t('create.loading') }}</span>
				</div>
				<div v-else>
					<QuestionListForm :questions="questions" v-model:q="questions" />
					<div class="group button">
						<SubmitButton ref="submitBtn" :text="$t('create.button')" />
						<span class="info">{{ $t('affiliate.info') }}</span>
					</div>
				</div>
			</form>
		</section>
	</div>
</template>

<style lang="css" scoped>
form div.group.button
{
	margin-top: 3rem;
	text-align: center;
}

form div.group.button button
{
	display: block;
	width: 100%;
}
</style>