import './assets/main.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { i18n } from './i18n/i18n'

import App from './App.vue'
import router from './router'
import { createHead } from '@vueuse/head'

const app = createApp(App)
const head = createHead()
const storage = createPinia()

if( "serviceWorker" in navigator )
{
	//console.log("Service Worker vorhanden")

	navigator.serviceWorker
	.register("/firebase-messaging-sw.js")
	/*
	.then((registration) => {
		console.log("Service Worker registriert:", registration);
	})
	.catch((error) => {
		console.error("Service Worker Registrierung fehlgeschlagen:", error);
	});
	*/
}

app.use(storage)
app.use(router)
app.use(head)
app.use(i18n)

app.mount('#app')
