import { defineStore } from 'pinia'
import { useLocalStorage, useSessionStorage } from '@vueuse/core'
import type IQuestionClient from '@/interfaces/IQuestionClient'
import { Timestamp } from 'firebase/firestore'
import type IQuizClient from '@/interfaces/IQuizClient'
import type IQuizQuestions from '@/interfaces/IQuizQuestions'
import type IQuestionAnswerPair from '@/interfaces/IQuestionAnswerPair'

export const createQuizStorage = defineStore('c_quiz', () =>
{
	const attributes = useLocalStorage<{
		done: boolean,
		running: boolean,
		id: string | null,
		name: string | null,
		sex: string | null,
		created: Date | null,
		parentQuizId: string | null,
		parentAttendeeId: string | null,
		questions: Array<IQuestionClient>,
		uuid: string | null,
		msgToken: string | null,
	}>('c_quiz',
	{
		done: false,
		running: false,
		id: null,
		name: null,
		sex: null,
		created: null,
		parentQuizId: null,
		parentAttendeeId: null,
		questions: [],
		uuid: null,
		msgToken: null,
	})

	function create( name: string, sex: string, parentQuizId: string | null = null, parentAttendeeId: string | null = null ): void
	{
		clear()
		attributes.value.running = true
		attributes.value.name = name
		attributes.value.sex = sex
		attributes.value.parentQuizId = parentQuizId
		attributes.value.parentAttendeeId = parentAttendeeId
	}

	function setDone( d: boolean ): void
	{
		attributes.value.done = d
	}

	function isDone(): boolean
	{
		return attributes.value.done
	}

	function setRunning( running: boolean ): void
	{
		attributes.value.running = running
	}

	function isRunning(): boolean
	{
		return attributes.value.running
	}

	function setId( id: string ): void
	{
		attributes.value.id = id
	}

	function getId(): string
	{
		return attributes.value.id || ''
	}

	function getName(): string
	{
		return attributes.value.name || ''
	}

	function getSex(): string
	{
		return attributes.value.sex || ''
	}

	function setCreated( ts: Date ): void
	{
		attributes.value.created = ts
	}

	function getCreated(): Date | null
	{
		if( typeof attributes.value.created === 'string' )
		{
			return new Date(attributes.value.created)
		}

		return attributes.value.created
	}

	function getCreatedAsTimestamp(): Timestamp | null
	{
		if( attributes.value.created )
		{
			return Timestamp.fromDate( getCreated() as Date )
		}

		return null
	}

	function getParentQuizId(): string | null
	{
		return attributes.value.parentQuizId
	}

	function getParentAttendeeId(): string | null
	{
		return attributes.value.parentAttendeeId
	}

	function setQuestions( questions: Array<IQuestionClient> ): void
	{
		attributes.value.questions = questions
	}

	function hasQuestions(): boolean
	{
		return attributes.value.questions.length > 0
	}

	function getQuestions(): Array<IQuestionClient>
	{
		return attributes.value.questions
	}

	function getQuestionsAsObj(): IQuestionAnswerPair
	{
		const map: IQuestionAnswerPair = {}

		attributes.value.questions.forEach(qes =>
		{
			map[qes.id] = qes.selected as string
		})

		return map
	}

	function getUrl(): string
	{
		if( !isDone() )
		{
			return ''
		}

		const location = window.location
		return `${location.protocol}//${location.host}/test/${getId()}`
	}

	function setUUID( uuid: string ): void
	{
		attributes.value.uuid = uuid
	}

	function getUUID(): string
	{
		return attributes.value.uuid || ''
	}

	function setMsgToken( msgToken: string ): void
	{
		attributes.value.msgToken = msgToken
	}

	function getMsgToken(): string | null
	{
		return attributes.value.msgToken
	}

	function hasMsgToken(): boolean
	{
		return attributes.value.msgToken !== null
	}

	function clear(): void
	{
		attributes.value.done = false
		attributes.value.running = false
		attributes.value.id = null
		attributes.value.name = null
		attributes.value.sex = null
		attributes.value.created = null
		attributes.value.parentQuizId = null
		attributes.value.questions = []
		attributes.value.uuid = null
	}

	return {
		attributes,
		create,
		setDone,
		isDone,
		setRunning,
		isRunning,
		setQuestions,
		getQuestions,
		getQuestionsAsObj,
		hasQuestions,
		setId,
		getId,
		getName,
		getSex,
		setCreated,
		getCreated,
		getCreatedAsTimestamp,
		getParentQuizId,
		getParentAttendeeId,
		getUrl,
		setUUID,
		getUUID,
		setMsgToken,
		getMsgToken,
		hasMsgToken,
		clear,
	}
})

export const joinQuizStorage = defineStore('j_quiz', () =>
{
	const attributes = useSessionStorage<{
		exists: boolean,
		id: string | null,
		name: string | null,
		sex: string | null,
		created: Date | null,
		questions: IQuizQuestions | null
		answers: IQuestionAnswerPair | {},
		msgToken: string | null,
	}>('j_quiz',
	{
		exists: false,
		id: null,
		name: null,
		sex: null,
		created: null,
		questions: null,
		answers: {},
		msgToken: null,
	})

	function create(id: string | null,
					name: string | null,
					sex: string | null,
					created: Date | null,
					questions: IQuizQuestions | null,
					msgToken: string | null ): void
	{
		clear()
		attributes.value.exists = true
		attributes.value.id = id
		attributes.value.name = name
		attributes.value.sex = sex
		attributes.value.created = created
		attributes.value.questions = questions
		attributes.value.msgToken = msgToken
	}

	function isExisting(): boolean
	{
		return attributes.value.exists
	}

	function getId(): string
	{
		return attributes.value.id || ''
	}

	function getName(): string
	{
		return attributes.value.name || ''
	}

	function getSex(): string
	{
		return attributes.value.sex || ''
	}

	function getCreated(): Date | null
	{
		if( typeof attributes.value.created === 'string' )
		{
			return new Date(attributes.value.created)
		}

		return attributes.value.created
	}

	function getCreatedAsTimestamp(): Timestamp | null
	{
		if( attributes.value.created )
		{
			return Timestamp.fromDate( getCreated() as Date )
		}

		return null
	}

	function getQuestions(): IQuizQuestions | null
	{
		return attributes.value.questions
	}

	function hasQuestions(): boolean
	{
		return attributes.value.questions !== null
	}

	function getQuestionCount(): number
	{
		return Object.entries( attributes?.value?.questions || {} ).length
	}

	function addAnswerId( answer: IQuestionAnswerPair ): void
	{
		attributes.value.answers = Object.assign(attributes.value.answers, answer)
	}

	function getAnswers(): IQuestionAnswerPair
	{
		return attributes.value.answers
	}

	function hasAnswerIds(): boolean
	{
		return Object.entries(attributes.value.answers).length > 0
	}

	function getAnswerCount(): number
	{
		return Object.entries(attributes.value.answers).length
	}

	function getMsgToken(): string | null
	{
		return attributes.value.msgToken
	}

	function getObject(): IQuizClient
	{
		return {
			id: attributes.value.id,
			name: attributes.value.name,
			sex: attributes.value.sex,
			created: getCreatedAsTimestamp(),
			questions: attributes.value.questions,
			answers: attributes.value.answers,
			msgToken: attributes.value.msgToken,
		}
	}

	function clear(): void
	{
		attributes.value.exists = false
		attributes.value.name = null
		attributes.value.sex = null
		attributes.value.created = null
		attributes.value.questions = null
		attributes.value.msgToken = null
		attributes.value.answers = {}
	}

	return {
		attributes,
		create,
		isExisting,
		getId,
		getName,
		getSex,
		getCreated,
		getCreatedAsTimestamp,
		getQuestions,
		hasQuestions,
		getQuestionCount,
		addAnswerId,
		getAnswers,
		hasAnswerIds,
		getAnswerCount,
		getMsgToken,
		getObject,
		clear,
	}
})