<script setup lang="ts">
import { useClipboard } from '@vueuse/core'
import { analytics } from '@/firebase'
import { logEvent } from 'firebase/analytics'
import { createQuizStorage } from '@/stores'

const quiz = createQuizStorage()
const props = defineProps<{url: string}>()

const select = (el: FocusEvent) =>
{
	const elem = el.target as HTMLInputElement
	elem.select()
	elem.setSelectionRange(0, 99999)
}

const copy = () =>
{
	useClipboard().copy(props.url)
	logEvent( analytics, 'copied_link', {quizId: quiz.getId(), url: props.url} )
}
</script>

<template>
	<div class="copy-field">
		<input type="text" :value="url" @focus="select" readonly>
		<button type="button" @click="copy">{{ $t('copyfield.button') }}</button>
	</div>
</template>

<style lang="css" scoped>
div.copy-field
{
	display: flex;
}

div.copy-field input
{
	display: block;
	width: 100%;
	flex: 1;
	border: none;
	padding: .6rem 1rem .6rem 1.5rem;
	outline: none;
	background: #f8f8f8;
	box-sizing: border-box;
	font-size: 1.5rem;
	font-weight: 300;
	color: #999;
	border-radius: 50px 0 0 50px;
	box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
}

div.copy-field input::placeholder
{
	color: #999;
	font-weight: 300;
}

div.copy-field button
{
	display: block;
	flex: 0;
	border: none;
	padding: .6rem 1.3rem .6rem 1rem;
	outline: none;
	box-sizing: border-box;
	border-radius: 0 50px 50px 0;
	background: var(--shade1-color);
	color: #fff;
	font-size: 1.5rem;
	font-weight: 600;
	cursor: pointer;
	box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

div.copy-field button:hover
{
	background: var(--shade2-color);
}

div.copy-field button:active
{
	box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
	background: var(--shade1-color);
	transform: translateY(2px);
}

@media screen and (max-width: 500px)
{
	div.copy-field
	{
		flex-direction: column;
	}

	div.copy-field input
	{
		border-radius: 20px 20px 0 0;
	}

	div.copy-field button
	{
		border-radius: 0 0 20px 20px;
	}
}
</style>