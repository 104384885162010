<script setup lang="ts">
import Alert from '@/components/Alert.vue'
import CreateForm from '@/components/CreateForm.vue'
import QuestionList from '@/components/QuestionList.vue'
import { getAttendee, getAttendeeResult, getCustomTestHeader, getQuestionAnswers, getQuiz, parseText } from '@/functions'
import type IAttendeeClient from '@/interfaces/IAttendeeClient'
import type IAttendeeQuestion from '@/interfaces/IAttendeeQuestion'
import type IQuizClient from '@/interfaces/IQuizClient'
import router from '@/router'
import { createQuizStorage } from '@/stores'
import { useTitle } from '@vueuse/core'
import { inject, onMounted, onUnmounted, reactive, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n, I18nT } from 'vue-i18n'
import { i18n } from '@/i18n/i18n'
import type IQuizQuestions from '@/interfaces/IQuizQuestions'
import { collection, documentId, getDocs, query, where } from 'firebase/firestore'
import { db } from '@/firebase'
import type IQuestionRes from '@/interfaces/IQuestionRes'
import { analytics } from '@/firebase'
import { setUserProperties, logEvent } from 'firebase/analytics'

//const { getAttendee, getAttendeeResult, getCustomTestHeader, getQuestionAnswers, getQuiz, parseText } = await import('../functions')

const { t, locale } = useI18n()

const route = useRoute()
const quiz = createQuizStorage()

const setHeaderTitle = inject('setHeaderTitle') as (title: string) => void

const quizId = route.params.quizId as string
const attendeeId = route.params.attendeeId as string

const attendeeLabel = ref<string>()
const attendeeData = reactive<{quiz: IQuizClient | null, attendee: IAttendeeClient | null}>({quiz: null, attendee: null})
const questions = reactive<Array<IAttendeeQuestion>>([])
const correctCount = ref<number>(0)
const allCount = ref<number>(0)
const percentage = ref<number>(0)

const showLoader = ref<boolean>(true)
const alertType = ref<string>('info')
const alertText = ref<string | null>(null)

const loadedQuestions: Array<IQuestionRes> = []

const loadQuestions = async ( quiz: IQuizClient, attendee: IAttendeeClient ) =>
{
	const lang = i18n.global.locale
	questions.splice(0, questions.length)
	correctCount.value = 0
	allCount.value = 0
	percentage.value = 0

	const questionIds: Array<string> = []
	const quizQuestions = quiz.questions as IQuizQuestions
	Object.keys( quizQuestions ).forEach(id => questionIds.push(id))

	const questionDocs = await getDocs( query( collection( db, 'questions' ), where( documentId(), 'in', questionIds ) ) )

	if( questionDocs.empty )
	{
		throw new Error(t('attendee.errors.no_questions'))
	}

	for( const doc of questionDocs.docs )
	{
		const data = Object.assign({id: doc.id}, doc.data()) as IQuestionRes
		const correctAnswer = quizQuestions[data.id].answer
		const custom = quizQuestions[data.id].custom
		const answers = getQuestionAnswers( data, quiz.name as string, quiz.sex as string, custom )

		let genderQuestion = null
		switch( quiz.sex )
		{
			case 'male':
				genderQuestion = ( data.questions.male && lang in data.questions.male ) ? data.questions.male[lang] : null
			break
			case 'female':
				genderQuestion = ( data.questions.female && lang in data.questions.female ) ? data.questions.female[lang] : null
			break
		}

		questions.push({
			id: data.id,
			question: parseText(genderQuestion || data.questions.question[lang], quiz.name as string),
			correctAnswer,
			answers,
		})

		if( correctAnswer === attendee.answers[data.id] )
		{
			correctCount.value++
		}

		loadedQuestions.push(data)

		allCount.value++
	}

	percentage.value = (correctCount.value / allCount.value) * 100
}

const reloadQuestions = ( quiz: IQuizClient, attendee: IAttendeeClient ) =>
{
	const lang = i18n.global.locale
	questions.splice(0, questions.length)
	correctCount.value = 0
	allCount.value = 0
	percentage.value = 0

	const questionIds: Array<string> = []
	const quizQuestions = quiz.questions as IQuizQuestions
	Object.keys( quizQuestions ).forEach(id => questionIds.push(id))

	for( const data of loadedQuestions )
	{
		const correctAnswer = quizQuestions[data.id].answer
		const custom = quizQuestions[data.id].custom
		const answers = getQuestionAnswers( data, quiz.name as string, quiz.sex as string, custom )

		let genderQuestion = null
		switch( quiz.sex )
		{
			case 'male':
				genderQuestion = ( data.questions.male && lang in data.questions.male ) ? data.questions.male[lang] : null
			break
			case 'female':
				genderQuestion = ( data.questions.female && lang in data.questions.female ) ? data.questions.female[lang] : null
			break
		}

		questions.push({
			id: data.id,
			question: parseText(genderQuestion || data.questions.question[lang], quiz.name as string),
			correctAnswer,
			answers,
		})

		if( correctAnswer === attendee.answers[data.id] )
		{
			correctCount.value++
		}

		allCount.value++
	}

	percentage.value = (correctCount.value / allCount.value) * 100
}

onMounted(async () =>
{
	try
	{
		const quiz = await getQuiz( quizId )

		if( !quiz )
		{
			throw new Error(t('attendee.errors.no_quiz'))
		}

		const attendee = await getAttendee( quiz, attendeeId )

		if( !attendee )
		{
			throw new Error(t('attendee.errors.no_attendee'))
		}

		await loadQuestions( quiz, attendee )

		attendeeData.quiz = quiz
		attendeeData.attendee = attendee

		setHeaderTitle( getCustomTestHeader( quiz.name as string ) )

		switch( attendee.sex )
		{
			case 'male':
				attendeeLabel.value = t('attendee.details.title.male', {name: attendeeData.attendee.name})
			break
			case 'female':
				attendeeLabel.value = t('attendee.details.title.female', {name: attendeeData.attendee.name})
			break
			case 'x':
				attendeeLabel.value = t('attendee.details.title.x', {name: attendeeData.attendee.name})
			break
		}

		useTitle(t('attendee.head.title', {name: attendeeData.attendee.name}))
	}
	catch( e )
	{
		showLoader.value = false
		alertType.value = 'fail'
		alertText.value = (e as Error).message
	}
})

onUnmounted(() =>
{
	if( !route.path.match(/^\/test\/([A-Za-z0-9]+)$/) )
	{
		setHeaderTitle(t('title', {year: new Date().getFullYear()}))
	}
})

const create = ( name: string, sex: string ) =>
{
	quiz.create(name, sex, quizId, attendeeId)

	setUserProperties( analytics, {name, sex} )
	logEvent( analytics, 'quiz_create_by_attendee', {name, sex, quizId, attendeeId} )

	router.push('/create')
}

watch(locale, async () =>
{
	if( attendeeData.quiz && attendeeData.attendee )
	{
		if( loadedQuestions.length === 0 )
		{
			await loadQuestions( attendeeData.quiz, attendeeData.attendee )
		}
		else
		{
			reloadQuestions( attendeeData.quiz, attendeeData.attendee )
		}
	}
})
</script>

<template>
	<div>
		<Alert :type="alertType" :text="alertText" v-show="alertText" />
		<section v-if="attendeeData.attendee">
			<div class="head">
				<h2>{{ attendeeLabel }}</h2>
				<span class="info">
					<i18n-t keypath="attendee.details.created_at" scope="global">
						{{ attendeeData.attendee?.created.toDate().toLocaleString(locale, {dateStyle: 'short', timeStyle: 'short'}) }}
						<RouterLink :to="`/test/${attendeeData?.quiz?.id}`">{{ attendeeData?.quiz?.name }}</RouterLink>
					</i18n-t>
				</span>
				<p v-if="attendeeData.attendee?.message">{{ attendeeData.attendee.message }}</p>
			</div>
		</section>
		<div v-if="attendeeData.attendee" class="attendee">
			<section class="questions">
				<h2>{{ $t('attendee.questions.title', {name: attendeeData.quiz?.name}) }}</h2>
				<div class="progress">
					<div class="bar" :data-correct="correctCount">
						<span>{{ $t('attendee.questions.result', {correct: correctCount, all: allCount}) }}</span>
						<div :style="{ width: `${percentage}%` }"></div>
					</div>
					<div class="text">{{ getAttendeeResult( percentage, attendeeData.quiz?.name as string ) }}</div>
				</div>
				<QuestionList :questions="questions" :selected-answers="attendeeData.attendee?.answers" />
			</section>
			<section class="create" v-if="!quiz.isDone()">
				<h2>{{ $t('attendee.create.title') }}</h2>
				<p class="text-center">{{ $t('attendee.create.description') }}</p>
				<CreateForm
					@create="create"
					:name="attendeeData.attendee.name"
					:sex="attendeeData.attendee.sex"
					:button-label="$t('attendee.create.button')"
					:autofocus="false"
					:submit-btn="null"
					:message-label="null" />
			</section>
		</div>
		<section v-else-if="showLoader">
			<div class="loader" v-if="questions.length === 0">
				<img src="../assets/loader.svg" alt="">
				<span>{{ $t('attendee.questions.loading') }}</span>
			</div>
		</section>
		<RouterLink to="/" class="big-btn" v-else>
			<h2>{{ $t('buttons.ft.title') }}</h2>
			<p>{{ $t('buttons.ft.description') }}</p>
		</RouterLink>
	</div>
</template>

<style lang="css" scoped>
.head
{
	margin: 0;
	text-align: center;
}

.head h2
{
	margin: 0;
}

.head .info
{
	margin-top: .2rem;
	font-size: .85rem;
	letter-spacing: .1rem;
	text-transform: uppercase;
}

.head p
{
	font-style: italic;
	quotes: "“" "”";
	margin-bottom: 0;
}

.head p::before
{
	content: open-quote;
}

.head p::after
{
	content: close-quote;
}

div.progress
{
	margin: 1.5rem 0;
}

div.progress div.text
{
	text-align: center;
	color: #999;
	margin-top: 1rem;
}

div.progress div.bar
{
	position: relative;
	width: 100%;
	height: 30px;
	background: #ccc;
	border-radius: 25px;
	overflow: hidden;
	box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
}

div.progress div.bar > div
{
	height: 100%;
	background: var(--primary-color);
}

div.progress div.bar > span
{
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: #fff;
	font-weight: bold;
	text-shadow: 0 1px 2px rgba(0,0,0,.7);
}

div.progress div.bar[data-correct='1'] > div
{
	background: var(--result0-color);
}

div.progress div.bar[data-correct='2'] > div
{
	background: var(--result1-color);
}

div.progress div.bar[data-correct='3'] > div
{
	background: var(--result2-color);
}

div.progress div.bar[data-correct='4'] > div
{
	background: var(--result3-color);
}

div.progress div.bar[data-correct='5'] > div
{
	background: var(--result4-color);
}

div.progress div.bar[data-correct='6'] > div
{
	background: var(--result5-color);
}

div.progress div.bar[data-correct='7'] > div
{
	background: var(--result6-color);
}

div.progress div.bar[data-correct='8'] > div
{
	background: var(--result7-color);
}

div.progress div.bar[data-correct='9'] > div
{
	background: var(--result8-color);
}

div.progress div.bar[data-correct='10'] > div
{
	background: var(--result9-color);
}

div.attendee
{
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
}

div.attendee section.questions
{
	padding: 1.5rem 2rem 2.5rem;
}

div.attendee section.create h2
{
	margin-bottom: 1rem;
}

div.attendee section.create p
{
	margin: 2rem 0;
}
</style>