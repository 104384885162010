<script setup lang="ts">
import { RouterView } from 'vue-router'
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
import { onMounted, provide, ref } from 'vue'
import TestStepBar from './components/TestStepBar.vue'
import { useHead } from '@vueuse/head'
import { getCurrentLanguage, getManifest } from './functions'
import { useI18n } from 'vue-i18n'
import * as lang from './i18n/index'
import webmanifest from './webmanifest.json'
import { onMessage, type MessagePayload } from 'firebase/messaging'
import { messaging } from './firebase'

//const { getCurrentHostname, getCurrentLanguage, getLocale, getManifest } = await import('./functions')

const { locale } = useI18n()

//const year = new Date().getFullYear()
//const localeStr = getLocale()
const localeLang = getCurrentLanguage()
//const currentHost = getCurrentHostname()

const headerTitle = ref<string>('')
const testBarShown = ref<boolean>(false)
const stepCount = ref<number>(0)
const allCount = ref<number>(0)
const langManifest = getManifest()

const isTestBarShown = () =>
{
	return testBarShown.value
}

const setHeaderTitle = (title: string) =>
{
	headerTitle.value = title
}

const activateTestBar = ( all: number ) =>
{
	testBarShown.value = true
	stepCount.value = 0
	allCount.value = all
}

const setTestBar = ( step: number ) =>
{
	stepCount.value = step
}

const deactivateTestBar = () =>
{
	testBarShown.value = false
	stepCount.value = 0
	allCount.value = 0
}

// Manifest
const manifestDomain = langManifest[locale.value].id
webmanifest.start_url = `${manifestDomain}${webmanifest.start_url}`
webmanifest.scope = `${manifestDomain}${webmanifest.scope}`
webmanifest.icons.map(icon => icon.src = `${manifestDomain}${icon.src}`)

const manifest = Object.assign(webmanifest, langManifest[locale.value])
const blobManifest = new Blob([JSON.stringify( manifest )], {type: 'application/json'})
const manifestUrl = URL.createObjectURL(blobManifest)

const alternates: Array<{rel: string, hreflang: string, href: string}> = []
const ogAlternates: string[] = []

for( const [code, val] of Object.entries(lang) )
{
	alternates.push({
		rel: 'alternate',
		hreflang: code,
		href: `https://${val.meta.domain}`
	})

	if( val.meta.code !== localeLang.code )
	{
		ogAlternates.push(val.meta.code)
	}
}

useHead({
	link: [
		{
			rel: 'manifest',
			href: manifestUrl,
		},
		{
			rel: 'dns-prefetch',
			href: 'https://www.googletagmanager.com/',
		},
		{
			rel: 'preload',
			href: 'https://www.googletagmanager.com/gtag/js?l=dataLayer&id=G-JB0F7HZT4K',
			as: 'script' as const,
		},
		{
			rel: 'preload',
			href: 'https://fonts.googleapis.com/css2?family=Lobster&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap',
			as: 'style' as const,
			type: 'text/css',
		},
		{
			rel: 'preconnect',
			href: 'https://fonts.googleapis.com',
		},
		{
			rel: 'preconnect',
			href: 'https://fonts.gstatic.com',
			crossOrigin: true,
		},
		{
			rel: 'stylesheet',
			href: 'https://fonts.googleapis.com/css2?family=Lobster&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap',
		},
	].concat(alternates),
})

/*
useSeoMeta({
	description: t('seo.description', {year}),

	ogType: 'website',
	ogTitle: t('seo.title', {year}),
	ogDescription: t('seo.description', {year}),
	ogImageUrl: `${currentHost}/og/${localeStr}.jpg`,
	ogImageSecureUrl: `${currentHost}/og/${localeStr}.jpg`,
	ogImageWidth: 1200,
	ogImageHeight: 630,
	ogImageType: 'image/jpeg',
	ogImageAlt: t('seo.description', {year}),
	ogDeterminer: 'a',
	ogUrl: currentHost,
	ogSiteName: t('seo.site_title'),
	ogLocale: localeLang.code,
	ogLocaleAlternate: ogAlternates,
	xUaCompatible: 'IE=edge',
	viewport: 'width=device-width, initial-scale=1.0',

	twitterCard: 'summary_large_image',
	twitterTitle: t('seo.title', {year}),
	twitterDescription: t('seo.description', {year}),
	twitterImage: `${currentHost}/og/${localeStr}.jpg`,
	twitterImageAlt: t('seo.description', {year}),

	msapplicationTileColor: '#185a9d',
	themeColor: '#185a9d',
})
*/

onMounted(() =>
{
	onMessage(messaging, (payload: MessagePayload) =>
	{
		//console.log("[App] Daten empfangen: ", payload);

		if( payload )
		{
			if( Notification.permission === 'granted' )
			{
				const notification = new Notification( payload.data?.title || '',
				{
					body: payload.data?.body || '',
					icon: payload.data?.icon || '',
					silent: false,
					tag: 'friendshiptest',
				})

				notification.addEventListener('click', () =>
				{
					notification.close()
					if( payload.data?.url )
					{
						window.open(payload.data?.url, '_blank')
					}
				})
			}
		}
	})
})

provide('setHeaderTitle', setHeaderTitle)
provide('isTestBarShown', isTestBarShown)
provide('activateTestBar', activateTestBar)
provide('setTestBar', setTestBar)
provide('deactivateTestBar', deactivateTestBar)
</script>

<template>
	<div>
		<Header :name="headerTitle" />
		<main>
			<RouterView :key="$route.fullPath" />
		</main>
		<TestStepBar v-if="testBarShown && stepCount < allCount" :step="stepCount" :all="allCount" />
		<Footer />
	</div>
</template>

<style lang="css" scoped>
main > div
{
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1.5rem;
	margin-top: 95px;
	padding: 1.5rem 0;
}

main > div :deep(div.deeper)
{
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
	width: 100%;
	max-width: 780px;
}

main > div :deep(section)
{
	width: 100%;
	max-width: 780px;
	background: #fff;
	border-radius: 8px;
	padding: 1.25rem 2rem;
	box-sizing: border-box;
	box-shadow: rgba(14, 63, 126, 0.04) 0px 0px 0px 1px, rgba(42, 51, 69, 0.04) 0px 1px 1px -0.5px, rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px, rgba(42, 51, 70, 0.04) 0px 6px 6px -3px, rgba(14, 63, 126, 0.04) 0px 12px 12px -6px, rgba(14, 63, 126, 0.04) 0px 24px 24px -12px;
}

@media screen and (max-width: 800px)
{
	main > div
	{
		margin-top: 80px;
		padding-top: 1rem;
	}

	main > div :deep(section)
	{
		margin: 0;
		border-radius: 0;
	}
}

@media screen and (max-width: 450px)
{
	main > div :deep(section)
	{
		padding: 1.25rem 1rem !important;
	}
}
</style>
