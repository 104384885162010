<script setup lang="ts">
import { changeLanguage, getCurrentLanguage, getLanguages } from '@/functions'
import type ILang from '@/interfaces/ILang'
import { useHead } from '@vueuse/head'
import { onMounted, ref } from 'vue'
import { analytics } from '@/firebase'
import { logEvent } from 'firebase/analytics'

//const { changeLanguage, getCurrentLanguage, getLanguages } = await import('../functions')

const glob: Record<string, {default: string}> = import.meta.glob('@/assets/flags/*.png', { eager: true })
const flags = Object.fromEntries(Object.entries(glob).map(([key, value]) => [key.replace(/^.*[\\/]/, ''), value.default]))

const dropdown = ref<HTMLDivElement>()
const currentLang = ref<ILang>(getCurrentLanguage())
const currentLandFlag = ref<string>(currentLang.value.flag)

const change = ( newLocale: string ) =>
{
	const oldLang = currentLang.value.name

	changeLanguage( newLocale )
	currentLang.value = getCurrentLanguage()
	currentLandFlag.value = currentLang.value.flag

	logEvent( analytics, 'language_changed', {old: oldLang, new: currentLang.value.name} )
}

onMounted(() =>
{
	document.addEventListener('click', () =>
	{
		dropdown.value?.classList.remove('show')
	})
})

useHead({
	bodyAttrs: {class: import.meta.env.DEV ? 'bottom-space' : ''}
})
</script>

<template>
	<footer>
		<ul class="menu">
			<li><RouterLink :to="$t('footer.friendship.url')">{{ $t('footer.friendship.title') }}</RouterLink></li>
			<li><RouterLink to="/contact">{{ $t('footer.contact') }}</RouterLink></li>
			<li><RouterLink to="/tos">{{ $t('footer.tos') }}</RouterLink></li>
			<li><RouterLink to="/privacy">{{ $t('footer.privacy') }}</RouterLink></li>
			<li><RouterLink to="/imprint">{{ $t('footer.imprint') }}</RouterLink></li>
			<li class="languages">
				<button type="button" @click.stop.prevent="dropdown?.classList.toggle('show')">
					<img :src="flags[currentLandFlag]" :alt="$t('footer.language')" width="20" height="20">
					<span>{{ currentLang.name }}</span>
				</button>
				<div class="dropdown" ref="dropdown">
					<ul>
						<li v-for="(locale, lang) in getLanguages()" :key="lang" :class="{active: lang === $i18n.locale}">
							<button type="button" @click="change(lang as string)">
								<img :src="flags[locale.flag]" :alt="locale.name" width="20" height="20">
								<span>{{ locale.name }}</span>
							</button>
						</li>
					</ul>
				</div>
			</li>
		</ul>
	</footer>
</template>

<style lang="css" scoped>
footer
{
	margin: 0 .5rem 1rem;
}

footer.dev
{
	margin-bottom: 4rem;
}

ul.menu
{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	list-style: none;
	margin: 0;
	padding: 0;
}

ul.menu > li
{
	display: block;
}

ul.menu > li > a,
ul.menu > li > button
{
	font-size: .8rem;
	text-transform: uppercase;
	letter-spacing: .1rem;
	padding: .4rem .8rem;
	color: #999;
	text-decoration: none;
}

ul.menu > li > button
{
	display: inline;
	border: none;
	letter-spacing: normal;
	padding: .3285rem .8rem;
	background: transparent;
	cursor: pointer;
	line-height: 0;
}

ul.menu > li > button img
{
	display: inline-block;
	height: 20px;
	vertical-align: middle;
	margin-right: .4rem;
}

ul.menu > li > button span
{
	display: inline-block;
	vertical-align: middle;
	line-height: normal;
}

ul.menu > li > a:hover,
ul.menu > li > button:hover span
{
	text-decoration: underline;
}

ul.menu > li.languages
{
	position: relative;
	line-height: 1.2rem;
	white-space: nowrap;
}

ul.menu > li.languages .dropdown
{
	position: absolute;
	min-width: 160px;
	padding: .5rem 0;
	background: #fff;
	border-radius: 8px;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
	right: 0;
	bottom: 35px;
	transform: translateY(10px);
	opacity: 0;
	visibility: hidden;
	transition: transform .25s ease, opacity .125s ease, visibility .125s ease;
}

ul.menu > li.languages .dropdown.show
{
	transform: translateY(0);
	opacity: 1;
	visibility: visible;
}

ul.menu > li.languages .dropdown::after
{
	content: '';
	display: block;
	position: absolute;
	right: 15px;
	bottom: -5px;
	width: 15px;
	height: 15px;
	transform: rotate(45deg);
	background: #fff;
	z-index: 1;
}

ul.menu > li.languages .dropdown ul
{
	position: relative;
	display: flex;
	flex-direction: column;
	margin: 0;
	padding: 0;
	list-style: none;
	z-index: 2;
}

ul.menu > li.languages .dropdown ul > li
{
	display: block;
}

ul.menu > li.languages .dropdown ul button
{
	display: block;
	width: 100%;
	padding: .5rem 1rem;
	text-align: left;
	letter-spacing: normal;
	border: none;
	background: transparent;
	cursor: pointer;
	font-size: .8rem;
	text-transform: uppercase;
	color: #999;
	text-decoration: none;
	white-space: nowrap;
}

ul.menu > li.languages .dropdown ul button:hover,
ul.menu > li.languages .dropdown ul li.active button
{
	background: var(--primary-color);
	color: #fff;
}

ul.menu > li.languages .dropdown ul button img
{
	display: inline-block;
	height: 20px;
	vertical-align: middle;
	margin-right: .4rem;
}

ul.menu > li.languages .dropdown ul button span
{
	display: inline-block;
	vertical-align: middle;
	line-height: normal;
}
</style>