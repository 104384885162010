<script setup lang="ts">
import { onMounted, ref, type Ref } from 'vue'

defineProps<{text: string}>()

const submitted = ref<boolean>(false)
const btn = ref<HTMLButtonElement>()

onMounted(() =>
{
	btn?.value?.closest('form')?.addEventListener('submit', () =>
	{
		submitted.value = true
	})
})

const reset = () =>
{
	submitted.value = false
}

defineExpose({
	reset,
})
</script>

<template>
	<button ref="btn" type="submit" :disabled="submitted">
		<span v-if="submitted" class="loader"><img src="../assets/loader-white.svg" alt=""></span>
		<span v-else>{{ text }}</span>
	</button>
</template>

<style lang="css" scoped>
button
{
	padding: calc(.6rem + 2px) 1rem;
	border: none;
	border-radius: 50px;
	background: var(--shade1-color);
	color: #fff;
	font-size: 1.5rem;
	font-weight: 600;
	cursor: pointer;
	box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
	transition: background-color .3s ease;
}

button > span.loader > img
{
	display: inline-block;
	height: 30px;
}

button:not([disabled]):hover
{
	background: var(--shade2-color);
}

button:not([disabled]):active
{
	box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
	background: var(--shade1-color);
	transform: translateY(2px);
}
</style>