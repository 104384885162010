<script setup lang="ts">
import type IAttendeeQuestion from '@/interfaces/IAttendeeQuestion'
import AnswerList from './AnswerList.vue'
import type IQuestionAnswerPair from '@/interfaces/IQuestionAnswerPair'

const props = withDefaults(defineProps<{questions: Array<IAttendeeQuestion>, selectedAnswers: IQuestionAnswerPair | null}>(),
{
	selectedAnswers: null,
})

const selectedAnswersComp = ( qId: string ) =>
{
	if( !props.selectedAnswers || !props.selectedAnswers[qId] )
	{
		return null
	}

	return props.selectedAnswers[qId]
}
</script>

<template>
	<div class="questions">
		<dl v-for="(question, qIdx) in props.questions" :key="question.id">
			<dt :class="{ correct: selectedAnswersComp(question.id) === question.correctAnswer }">
				<i>{{ (qIdx + 1) }}</i>
				<h3 class="question">{{ question.question }}</h3>
			</dt>
			<dd>
				<AnswerList :answers="question.answers" :correctAnswer="question.correctAnswer" :selectedAnswer="selectedAnswersComp(question.id)" />
			</dd>
		</dl>
	</div>
</template>

<style lang="css" scoped>
div.questions
{
	display: flex;
	flex-direction: column;
	gap: 2rem;
}

div.questions dl
{
	margin: 0;
}

div.questions dl > dt
{
	display: flex;
	align-items: center;
}

div.questions dl > dt i
{
	display: flex;
	flex: 0 60px;
	height: 60px;
	align-items: center;
	justify-content: center;
	counter-increment: step;
	content: counter(step);
	color: #fff;
	background: var(--result0-color);
	font-style: normal;
	border-radius: 50%;
	font-size: 1.6rem;
	font-weight: 300;
	user-select: none;
	cursor: default;
}

div.questions dl > dt.correct i
{
	background: #329505;
}

div.questions dl > dt h3
{
	flex: 1;
	font-size: 1.5rem;
	margin-left: 20px;
	font-weight: 300;
}

div.questions dl > dd
{
	margin: 10px 0 0 80px;
}

@media screen and (max-width: 550px)
{
	div.questions dl > dd
	{
		margin-left: 0;
	}
}

@media screen and (max-width: 450px)
{
	div.questions dl > dt i
	{
		flex: 0 50px;
		height: 50px;
	}

	div.questions dl > dt h3
	{
		font-size: 1.25rem;
		margin-top: 0;
		margin-bottom: 0;
	}

	div.questions dl > dd
	{
		margin-top: 16px;
	}
}
</style>