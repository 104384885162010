<script setup lang="ts">
import type IAnswerClient from '@/interfaces/IAnswerClient'
import { useTextareaAutosize } from '@vueuse/core'

const emit = defineEmits(['changed'])
withDefaults(defineProps<{idx?: number, name?: string, answers: Array<IAnswerClient>, allowCustom?: boolean}>(),
{
	idx: 0,
	name: 'answer',
	allowCustom: false,
})

const selected = defineModel<string | null>('selected')
const custom = defineModel<string>('custom')
const { textarea, input } = useTextareaAutosize({ styleProp: 'minHeight', input: custom })
</script>

<template>
	<ul class="answers">
		<li v-for="(answer, aIdx) in answers" :key="aIdx">
			<label
				class="option"
				:for="idx + '-' + aIdx"
				:class="{ checked: selected === answer.idx }">
				<span class="checkbox">
					<input
						type="radio"
						:name="name"
						:id="idx + '-' + aIdx"
						:value="answer.idx"
						v-model="selected"
						required>
					<i></i>
				</span>
				<span class="label">
					{{ answer.answer }}
				</span>
			</label>
		</li>
		<li :key="idx + '-custom'" v-if="allowCustom">
			<div
				class="option custom"
				:class="{ checked: selected === 'custom' }">
				<span class="checkbox">
					<input
						type="radio"
						:name="name"
						:id="idx + '-custom'"
						value="custom"
						v-model="selected"
						required>
					<i></i>
				</span>
				<textarea
					ref="textarea"
					class="custom"
					:placeholder="$t('answerlistform.custom')"
					maxlength="50"
					rows="1"
					v-model.trim="input"
					:required="selected === 'custom'"
					@focus="selected = 'custom'"
					@keydown.enter.prevent="null"></textarea>
			</div>
		</li>
	</ul>
</template>

<style lang="css" scoped>
ul.answers
{
	display: flex;
	flex-direction: column;
	gap: .6rem;
	list-style: none;
	margin: 0;
	padding: 0;
}

ul.answers > li
{
	display: block;
}

ul.answers > li .option
{
	display: flex;
	align-items: stretch;
	border-radius: 50px;
	box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
	overflow: hidden;
	cursor: pointer;
}

ul.answers > li .option > span
{
	transition: background-color .3s ease, color .15s ease;
}

ul.answers > li .option.checked > span
{
	color: #fff !important;
	background: var(--shade3-color) !important;
}

ul.answers > li .option > span.checkbox
{
	position: relative;
	display: flex;
	align-items: center;
	flex: 0;
	background: #eee;
	padding: .6rem .8rem .6rem 1rem;
}

ul.answers > li .option > span.checkbox input[type="radio"]
{
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
	width: 100%;
	height: 100%;
	margin: 0;
	cursor: pointer;
}

ul.answers > li .option.checked > textarea.custom
{
	color: #fff;
	background: var(--shade3-color);
}

ul.answers > li .option > span.checkbox i
{
	position: relative;
	display: block;
	width: 18px;
	height: 18px;
	border-radius: 50%;
	border: 3px solid #fff;
}

ul.answers > li .option > span.checkbox i::before
{
	position: absolute;
	top: 4px;
	left: 4px;
	display: block;
	opacity: 0;
	content: '';
	width: 7px;
	height: 4px;
	border: 3px solid #fff;
	border-top: none;
	border-right: none;
	transform: rotate(-90deg);
	transition: transform .3s ease, opacity .15s ease;
}

ul.answers > li .option > span.checkbox input[type="radio"]:checked + i::before
{
	opacity: 1;
	transform: rotate(-45deg);
}

ul.answers > li .option > span.label
{
	display: flex;
	align-items: center;
	flex: 1;
	font-size: 1.2rem;
	font-weight: 300;
	padding: .5rem .625rem;
	box-sizing: border-box;
}

ul.answers > li .option.custom > span.checkbox input[type="radio"]
{
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	visibility: visible;
	opacity: 0;
	cursor: pointer;
	margin: 0;
	z-index: 2;
}

ul.answers > li .option > textarea.custom
{
	display: flex;
	width: 100%;
	align-items: center;
	flex: 1;
	font-size: 1.2rem;
	font-weight: 300;
	padding: .5rem .625rem;
	box-sizing: border-box;
	background: transparent;
	border: none;
	outline: none;
	-ms-overflow-style: none;
	scrollbar-width: none;
	transition: background-color .3s ease, color .15s ease;
}

ul.answers > li .option > textarea.custom::-webkit-scrollbar
{
	display: none;
}

ul.answers > li .option > textarea.custom::placeholder
{
	color: #999;
}

ul.answers > li .option.checked > textarea.custom::placeholder
{
	color: #f6f6f6;
}
</style>