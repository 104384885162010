<script setup lang="ts">
</script>

<template>
	<div>
		<section>
			<h2>{{ $t('imprint.title') }}</h2>
			<p>
				<strong>Mailuft Holding GmbH</strong><br>
				Hoher Markt 4/2/35<br>
				1010 Wien<br>
				Österreich
			</p>
			<p>
				<strong>E-Mail:</strong> info@mailuft.com
			</p>
			<p>
				<strong>FB-Nr:</strong> FN459153a<br>
				<strong>UID:</strong> ATU65399623<br>
				<strong>Gericht:</strong> Handelsgericht Wien
			</p>
		</section>
	</div>
</template>

<style lang="css" scoped>
</style>