<script setup lang="ts">
</script>

<template>
	<div>
		<section>
			<h2>{{ $t('privacy.title') }}</h2>
		</section>
	</div>
</template>

<style lang="css" scoped>
</style>