<script setup lang="ts">
import type IAnswerClient from '@/interfaces/IAnswerClient'

const props = withDefaults(defineProps<{answers: Array<IAnswerClient>, correctAnswer: string, selectedAnswer: string | null}>(),
{
	selectedAnswer: null,
})
</script>

<template>
	<ul class="answers">
		<li v-for="(answer, aIdx) in props.answers" :key="aIdx">
			<div class="option" :class="{ correct: correctAnswer === answer.idx, chosen: selectedAnswer === answer.idx }">
				<span>{{ answer.answer }}</span>
			</div>
		</li>
	</ul>
</template>

<style lang="css" scoped>
ul.answers
{
	display: flex;
	flex-direction: column;
	gap: .6rem;
	list-style: none;
	margin: 0;
	padding: 0;
}

ul.answers > li
{
	display: block;
}

ul.answers > li .option
{
	display: flex;
	align-items: stretch;
	border-radius: 50px;
	font-weight: 300;
	box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
	overflow: hidden;
}

ul.answers > li .option.correct
{
	font-weight: 600 !important;
	color: #329505;
}

ul.answers > li .option.chosen:not(.correct)
{
	font-weight: 600 !important;
	color: var(--result0-color);
}

ul.answers > li .option > span
{
	display: flex;
	align-items: center;
	flex: 1;
	font-size: 1.2rem;
	padding: .5rem 1.5rem;
	box-sizing: border-box;
}
</style>