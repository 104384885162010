<script setup lang="ts">
import CopyField from '@/components/CopyField.vue'
import { db, firebaseConfig, functions, messaging } from '@/firebase'
import { evaluateCount } from '@/functions'
import type IAttendeeClient from '@/interfaces/IAttendeeClient'
import type IAttendeeRes from '@/interfaces/IAttendeeRes'
import router from '@/router'
import { createQuizStorage } from '@/stores'
import { useHead } from '@vueuse/head'
import { collection, doc, getDoc, onSnapshot, orderBy, query } from 'firebase/firestore'
import { onMounted, reactive, ref } from 'vue'
import { useI18n, I18nT } from 'vue-i18n'
import sharethis from '../i18n/sharethis.json'
import { analytics } from '@/firebase'
import { logEvent } from 'firebase/analytics'
import { getToken } from 'firebase/messaging'
import { httpsCallable } from 'firebase/functions'
import { useTitle } from '@vueuse/core'

const addNotification = httpsCallable<{
	quizId: string,
	uuid: string,
	msgToken: string,
},
{
	ts: string,
	error: {
		message: string,
		code: number,
	}
}>(functions, 'addNotification')

const deleteQuiz = httpsCallable<{
	quizId: string,
	uuid: string,
},
{
	ts: string,
	error: {
		message: string,
		code: number,
	}
}>(functions, 'deleteQuiz')

const stUrls: {[key: string]: string} = {}
Object.entries(sharethis).forEach(l =>
{
	stUrls[l[0]] = l[1]
})

const { t, locale } = useI18n()

const quiz = createQuizStorage()
const attendees = reactive<Array<IAttendeeClient>>([])

const quizId = ref<string>( quiz.getId() )
const quizName = ref<string>( quiz.getName() )
const quizCreated = ref<Date>( quiz.getCreated() as Date )
const quizUrl = ref<string>( quiz.getUrl() + '?ref=share' )

const notificationAvailable = ref<boolean>(true)

onMounted(async () =>
{
	if( !quiz.isDone() || !(await getDoc( doc( db, 'quizzes', quizId.value ) )).exists() )
	{
		quiz.clear()
		router.push('/')
	}
	else
	{
		notificationAvailable.value = 'Notification' in window && Notification.permission === 'default'
	
		useTitle(t('result.head.title'))
		useHead({
			script: [
				{
					src: stUrls[window.location.hostname],
					async: true,
					onload: () =>
					{
						if( '__sharethis__' in window )
						{
							const __sharethis__ = window['__sharethis__'] as {on: (event: string, callback: CallableFunction) => {}}
							__sharethis__.on('share', () =>
							{
								logEvent( analytics, 'share', {quizId: quizId.value} )
							})
						}
					}
				}
			],
		})
	
		// Untested
		if( 'permissions' in navigator )
		{
			navigator.permissions.query({name: 'notifications'}).then(notification =>
			notification.addEventListener('change', () =>
			notificationAvailable.value = (notification.state === 'prompt')))
		}
	
		onSnapshot( query( collection( db, `/quizzes/${quizId.value}/attendees` ), orderBy('created', 'desc') ), snaphot =>
		{
			if( snaphot.empty )
			{
				attendees.splice(0, attendees.length)
			}
			else
			{
				attendees.splice(0, attendees.length)
				snaphot.docs.forEach(async doc =>
				{
					const data = Object.assign({id: doc.id}, doc.data()) as IAttendeeRes
	
					attendees.push({
						id: data.id,
						name: data.name,
						sex: data.sex,
						created: data.created,
						message: data.message || undefined,
						answers: data.answers,
						correctAnswers: evaluateCount( quiz.getQuestionsAsObj(), data.answers ),
						allAnswers: Object.values( data.answers ).length,
						new: data.created.toDate().getTime() > (Date.now() - 600000),
					})
				})
			}
		})
	
		setInterval(() =>
		attendees.forEach(attendee =>
		attendee.new = attendee.created.toDate().getTime() > (Date.now() - 600000)), 1000)
	}
})

const notifyMe = () =>
{
	Notification.requestPermission()
	.then(permission =>
	{
		if( permission === 'granted' )
		{
			logEvent( analytics, 'notifications_granted' )

			notificationAvailable.value = false
			getToken(messaging, {vapidKey: firebaseConfig.vapidKey})
			.then((msgToken: string) =>
			{
				addNotification({quizId: quiz.getId(), uuid: quiz.getUUID(), msgToken})
				.then(() =>
				{
					quiz.setMsgToken(msgToken)
					logEvent( analytics, 'notifications_activated' )
				})
				.catch(() => notificationAvailable.value = true)
			})
			.catch(() => notificationAvailable.value = true)
		}
		else if( permission === 'denied' )
		{
			logEvent( analytics, 'notifications_denied' )
			notificationAvailable.value = false
		}
	})
}

const deleteQuizConf = () =>
{
	if( confirm( t('result.delete.confirm') ) )
	{
		deleteQuiz({
			quizId: quiz.getId(),
			uuid: quiz.getUUID(),
		})

		logEvent( analytics, 'quiz_deleted', {quizId: quizId.value} )
	
		quiz.clear()
		router.push('/')
	}
}
</script>

<template>
	<div>
		<section>
			<div class="head">
				<h2>{{ $t('result.title', {name: quizName}) }}</h2>
				<span class="info">{{ $t('result.created_at', {date: quizCreated?.toLocaleString(locale, {dateStyle: 'short'})}) }}</span>
			</div>
		</section>
		<section>
			<div class="share">
				<h3>{{ $t('result.share.head') }}</h3>
				<div
					class="sharethis-inline-share-buttons"
					v-bind:data-url="quizUrl"
					v-bind:data-title="$t('result.share.title', {name: quizName})"
					v-bind:data-description="$t('result.share.description', {name: quizName})"></div>
			</div>
			<p class="text-center">{{ $t('result.share.alternative') }}</p>
			<CopyField :url="quizUrl" />
			<div class="notification text-center" v-if="notificationAvailable">
				<span class="fake-link" tabindex="0" @click="notifyMe">Benachrichtige mich über neue Teilnehmer</span>
			</div>
		</section>
		<section>
			<h2>{{ $t('result.attendees.title', {num: attendees.length}, attendees.length) }}</h2>
			<div class="no-attendees" v-if="attendees.length === 0">
				<span>{{ $t('result.attendees.empty') }}</span>
			</div>
			<TransitionGroup name="list" tag="ul" class="attendees" v-else>
				<li v-for="attendee in attendees" :key="attendee.id">
					<RouterLink
						@click="logEvent(analytics, 'result_opened', {quizId, attendeeId: attendee.id})"
						:to="`/test/${quizId}/attendee/${attendee.id}`"
						:class="{ new: attendee.new }">
						<div>
							<strong>{{ attendee.name }}</strong>
							<span v-if="attendee.message" class="info">{{ attendee.message }}</span>
						</div>
						<span>
							<span>{{ attendee.created.toDate().toLocaleString(locale, {dateStyle: 'short', timeStyle: 'short'}) }}</span>
						</span>
						<span>
							<span :data-correct="attendee.correctAnswers" class="result"><b>{{ attendee.correctAnswers }} / {{ attendee.allAnswers }}</b></span>
							<span class="bar" :data-correct="attendee.correctAnswers">
								<span :style="{width: `${((attendee.correctAnswers as number) / (attendee.allAnswers as number) * 100)}%`}"></span>
							</span>
						</span>
					</RouterLink>
				</li>
			</TransitionGroup>
		</section>
		<section>
			<h2>{{ $t('result.delete.title') }}</h2>
			<p class="text-center">
				<I18nT keypath="result.delete.text.text" scope="global">
					<span class="fake-link" tabindex="0" @click="deleteQuizConf">{{ $t('result.delete.text.click') }}</span>
				</I18nT>
				<br>
				<I18nT keypath="result.delete.warning.text" scope="global">
					<b>{{ $t('result.delete.warning.title') }}</b>
				</I18nT>
			</p>
		</section>
	</div>
</template>

<style lang="css" scoped>
.head
{
	margin: 0;
	text-align: center;
}

.head h2
{
	margin: 0;
}

.head .info
{
	margin-top: .2rem;
	font-size: .85rem;
	letter-spacing: .1rem;
	text-transform: uppercase;
}

h3
{
	font-weight: 500;
	margin: 0 0 1rem;
	color: var(--shade1-color);
	font-size: 1.1rem;
	letter-spacing: .1rem;
	text-align: center;
	text-transform: uppercase;
}

p
{
	margin: 1.5rem 0;
}

div.no-attendees
{
	margin: 2rem 1rem;
	text-align: center;
}

div.no-attendees span
{
	display: block;
	margin-top: 1rem;
	font-size: 1rem;
	font-weight: 300;
	letter-spacing: .1rem;
	text-transform: uppercase;
	color: #888;
}

div.notification
{
	margin-top: .8rem;
}

ul.attendees
{
	display: flex;
	flex-direction: column;
	gap: .6rem;
	margin: 0;
	padding: 0;
	list-style: none;
}

ul.attendees > li
{
	display: block;
}

ul.attendees > li a
{
	display: flex;
	align-items: stretch;
	border-radius: 50px;
	box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
	overflow: hidden;
	text-decoration: none;
	padding: .6rem .85rem .6rem 1.5rem;
	color: var(--text-color);
	background: #fff;
}

ul.attendees > li a.new
{
	box-shadow: 0 0 0 3px #5fa3e9;
}

ul.attendees > li a > div
{
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: center;
}

ul.attendees > li a > div .info
{
	display: block;
	margin-top: 2px;
}

ul.attendees > li a > span
{
	display: flex;
	justify-content: center;
	flex-direction: column;
	flex: 0 30px;
	white-space: nowrap;
	padding: 0 1rem;
	border-left: 1px solid #ccc;
}

ul.attendees > li a > span > span
{
	display: block;
}

ul.attendees > li a span.result
{
	width: 52px;
	text-align: center;
}

ul.attendees > li a span.result[data-correct='1']
{
	color: var(--result0-color);
}

ul.attendees > li a span.result[data-correct='2']
{
	color: var(--result1-color);
}

ul.attendees > li a span.result[data-correct='3']
{
	color: var(--result2-color);
}

ul.attendees > li a span.result[data-correct='4']
{
	color: var(--result3-color);
}

ul.attendees > li a span.result[data-correct='5']
{
	color: var(--result4-color);
}

ul.attendees > li a span.result[data-correct='6']
{
	color: var(--result5-color);
}

ul.attendees > li a span.result[data-correct='7']
{
	color: var(--result6-color);
}

ul.attendees > li a span.result[data-correct='8']
{
	color: var(--result7-color);
}

ul.attendees > li a span.result[data-correct='9']
{
	color: var(--result8-color);
}

ul.attendees > li a span.result[data-correct='10']
{
	color: var(--result9-color);
}

ul.attendees > li a span.bar

{
	margin-top: .4rem;
	width: 100%;
	height: 4px;
	border-radius: 4px;
	background: #ddd;
	overflow: hidden;
}

ul.attendees > li a span.bar > span
{
	display: block;
	height: 100%;
}

ul.attendees > li a span.bar[data-correct='1'] > span
{
	background: var(--result0-color);
}

ul.attendees > li a span.bar[data-correct='2'] > span
{
	background: var(--result1-color);
}

ul.attendees > li a span.bar[data-correct='3'] > span
{
	background: var(--result2-color);
}

ul.attendees > li a span.bar[data-correct='4'] > span
{
	background: var(--result3-color);
}

ul.attendees > li a span.bar[data-correct='5'] > span
{
	background: var(--result4-color);
}

ul.attendees > li a span.bar[data-correct='6'] > span
{
	background: var(--result5-color);
}

ul.attendees > li a span.bar[data-correct='7'] > span
{
	background: var(--result6-color);
}

ul.attendees > li a span.bar[data-correct='8'] > span
{
	background: var(--result7-color);
}

ul.attendees > li a span.bar[data-correct='9'] > span
{
	background: var(--result8-color);
}

ul.attendees > li a span.bar[data-correct='10'] > span
{
	background: var(--result9-color);
}

ul.attendees > li a > span:last-child
{
	padding-right: .5rem;
}

/* apply transition to moving elements */
.list-move,
.list-enter-active,
.list-leave-active
{
	transition: all 0.5s ease;
	transform-origin: center;
}

.list-enter-from,
.list-leave-to
{
	opacity: 0;
	transform: translateX(30px);
}

/* ensure leaving items are taken out of layout flow so that moving animations can be calculated correctly. */
.list-leave-active
{
	position: absolute;
}

@media screen and (max-width: 550px)
{
	ul.attendees > li a
	{
		flex-wrap: wrap;
		border-radius: 20px;
		padding: .6rem 1rem;
	}

	ul.attendees > li a > div
	{
		flex: 1 100%;
		margin-bottom: .5rem;
		padding-bottom: .5rem;
		border-bottom: 1px solid #ddd;
	}

	ul.attendees > li a > div + span
	{
		border-left: none;
		padding-left: 0;
	}
}
</style>